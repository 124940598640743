import * as Sentry from '@sentry/browser';
import { Config } from 'src/types/constants/Config';

export function initSentry(): void {
    if (isDev()) {
        return;
    }
    Sentry.init({
        dsn: `https://token@${window.location.hostname}/_report/1`,
        release: process.env.RELEASE_VERSION
    });
}

export function configureSentry(config: Config): void {
    if (isDev()) {
        return;
    }
    Sentry.configureScope((scope) => scope.addEventProcessor((event) => ({
        ...event,
        environment: config.environment,
    })));
}

function isDev(): boolean {
    return process.env.NODE_ENV !== 'production';
}
