import * as moment from 'moment';
import { debounce, Cancelable } from 'lodash';
import { Ng1StateDeclaration, StateService, StateParams } from '@uirouter/angularjs';
import { Widget } from 'src/types/entities/Widget';
import { IActivityLogService } from 'src/modules/api/ActivityLogService';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { DateRange } from 'src/types/values/DateRange';

const dateFormat = 'YYYY-MM-DD';

export const ROUTES_STATES_ACTIVITY: ReadonlyArray<Ng1StateDeclaration> =  [
    {
        name: 'activityLog',
        parent: 'authorized:widget',
        url: '/activity?page&category&dateFrom&dateTo&query',
        component: 'activityPage',
        params: {
            query: {
                type: 'string',
                value: '',
                squash: false,
            },
            page: {
                type: 'int',
                value: 1,
                squash: true,
            },
            category: {
                type: 'string',
                value: '',
                squash: true,
                dynamic: false,
            },
        },
        resolve: {
            $title: () => {
                return 'Activity Log';
            },
            page: [
                '$stateParams',
                ({ page }: any) => page,
            ],
            query: [
                '$stateParams',
                ({ query }: any) => query,
            ],
            category: [
                '$stateParams',
                ({ category }: any) => category ? category : null,
            ],
            dateRange: [
                '$stateParams',
                ($stateParams: StateParams): DateRange => ({
                    from: parseDate($stateParams.dateFrom),
                    to: parseDate($stateParams.dateTo),
                }),
            ],
            activityLog: [
                'widget',
                'activityLogService',
                'query',
                'category',
                'dateRange',
                'page',
                (
                    widget: Widget,
                    activityLogService: IActivityLogService,
                    query: string,
                    category: string | null,
                    dateRange: DateRange,
                    page: UnsignedInteger,
                ) => {
                    const filter = {
                        query,
                        category,
                        dateFrom: dateRange.from ? dateRange.from.toISOString() : null,
                        dateTo: dateRange.to ? dateRange.to.clone().add(1, 'day').toISOString() : null,
                        page: page - 1,
                    };
                    return activityLogService.list(widget, filter).then((data) => data || null);
                },
            ],
            onCategoryChange: [
                '$state',
                ($state: StateService) => (category: string) => {
                    $state.go('activityLog', { category, page: 1 });
                }
            ],
            onPageChange: [
                '$state',
                ($state: StateService) => (page: UnsignedInteger) => {
                    $state.go('activityLog', { page });
                }
            ],
            onDatesChange: [
                '$state',
                ($state: StateService) => (dateRange: DateRange) => {
                    $state.go('activityLog', {
                        dateFrom: dateRange.from ? dateRange.from.format(dateFormat) : '',
                        dateTo: dateRange.to ? dateRange.to.format(dateFormat) : '',
                        page: 1,
                    });
                }
            ],
            onSearchChange: [
                '$state',
                ($state: StateService) => debounce((query: string) => {
                    $state.go('activityLog', { query, page: 1 });
                }, 500)
            ],
        },
        onExit: [
            'onSearchChange',
            (debounced: Cancelable) =>  debounced.cancel()
        ],
    },
];

function parseDate(value: string | undefined): moment.Moment | null {
    if (!value) {
        return null;
    }

    const date = moment(value, dateFormat, true);
    return date.isValid() ? date : null;
}
