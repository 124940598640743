import { createSelector } from 'reselect';
import { getSearchParams } from 'src/react/common/selectors/getSearchParams';
import { StatisticsPageParams } from 'src/react/statistics/state/StatisticsPageParams';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { StatisticsTab } from 'src/react/statistics/state/StatisticsTab';
import { isOneOf } from 'src/utils/isOneOf';
import * as moment from 'moment';
import { DEFAULT_DATE_RANGE } from 'src/react/common/constants/dateRanges';

export const getStatisticsPageParams = createSelector([
    getSearchParams,
], (searchParams): StatisticsPageParams => {
    const page = searchParams.get('page');
    const sort = searchParams.get('sort');
    const query = searchParams.get('query');
    const tab = searchParams.get('tab');
    const dateFrom = searchParams.get('dateFrom');
    const dateTo = searchParams.get('dateTo');
    const hasResults = searchParams.get('hasResults');

    return {
        query: query ? query : '',
        page: page
            ? parsePage(page)
            : 1,
        sort: sort ? sort : '-searchCount',
        tab: tab ? parseTab(tab) : StatisticsTab.Combined,
        dateRange: {
            from: dateFrom ? parseDate(dateFrom) : DEFAULT_DATE_RANGE.dateRange.from,
            to: dateTo ? parseDate(dateTo) : DEFAULT_DATE_RANGE.dateRange.to,
        },
        hasResults: hasResults ? hasResults : null,
    };
});

function parsePage(value: string): UnsignedInteger {
    const asNumber = parseInt(value, 10);
    return isFinite(asNumber) ? Math.max(1, asNumber) : 1;
}

function parseDate(value: string): moment.Moment|null {
    const date = moment(value, 'YYYY-MM-DD', true);
    return date.isValid() ? date : null;
}

function parseTab(value: string): StatisticsTab {
    const validTabs = [
        StatisticsTab.Combined,
        StatisticsTab.Successfull,
        StatisticsTab.Unsuccessful,
        StatisticsTab.Last,
    ];
    return isOneOf(value, validTabs) ? value : StatisticsTab.Combined;

}
